<template>
    <div class='practiceResult' v-if="practiceDetail">
        <div class="backButton" v-if="!theme">
            <span @click="goBack">
                <i class="back el-icon-arrow-left"></i>
                {{$t('WDNPC_WEB_RETURN',{text:'返回'})}}
            </span>
        </div>
        <div class="practiceResult-title">
            <div class="practiceResult-title-left">
                <h4>{{practiceDetail.practiceName}}</h4>
                <p>{{$t('WDNPC_WEB_PRACTICE_MODE',{text:'练习模式：'})}}{{practicetxt(practiceDetail.practiceModel)}}</p>
            </div>
            <div class="practiceResult-title-right">
                <h4>{{practiceDetail.rightRate}}%</h4>
                <p>{{$t('WDNPC_WEB_ACCURACY',{text:'正确率'})}}</p>
            </div>
            
        </div>
        <div class="practiceResult-content">
            <ul class="_clear">
                <li>
                    <div class="practiceResult-content-left">
                        <h2>{{practiceDetail.rightCount}}</h2>
                        <p>{{$t('WDNPC_WEB_WDNPC_WEB_ANSWER_RIGHT_DAO',{text:'答对(道）'})}}</p>
                    </div>
                    <svg-icon icon-class="right"></svg-icon>
                </li>
                <li>
                    <div class="practiceResult-content-left">
                        <h2>{{practiceDetail.errorCount}}</h2>
                        <p>{{$t('WDNPC_WEB_WRONG_ANSWER_DAO',{text:'答错(道）'})}}</p>
                    </div>
                    <svg-icon icon-class="unright"></svg-icon>
                </li>
                <li>
                    <div class="practiceResult-content-left">
                        <h2>{{practiceDetail.emptyCount}}</h2>
                        <p>{{$t('WDNPC_WEB_NO_ANSWER_DAO',{text:'未答(道）'})}}</p>
                    </div>
                    <svg-icon icon-class="unanswer"></svg-icon>
                </li>
                <li>
                    <div class="practiceResult-content-left">
                        <h2>{{practiceDetail.totalCount}}</h2>
                        <p>{{$t('WDNPC_WEB_NUMBER_OF_GENERAL_QUESTIONS',{text:'总题数(道）'})}}</p>
                    </div>
                    <svg-icon icon-class="parTotalCount"></svg-icon>
                </li>
                
            </ul>
            <div class="practiceResult-content-bottom">
                <p class="practiceAgain" @click="practiceAgain">{{$t('WDNPC_WEB_PRACTICE_AGAIN',{text:'重新练习'})}}</p>
                <p class="practiceShow" @click="practiceShow">{{$t('WDNPC_WEB_VIEW_EXERCISES',{text:'查看练习'})}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'practiceResult',
    data () {
        return {
            theme:false,
            practiceDetail:null,
        };
    },
    created(){
        this.findUserPaperInfo();
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.goBack, false); //false阻止默认事件
    },
    methods: {
        practicetxt(type){
            let txt=null;
            if(type==1){
                txt=this.$t('WDNPC_WEB_SEQUENTIAL_EXERCISE',{text:'顺序练习'});
            }else if(type==2){
                txt=this.$t('WDNPC_WEB_RANDOM_PRACTICE',{text:'随机练习'});
            }else if(type==3){
                txt=this.$t('WDNPC_WEB_TAGGING_EXERCISE',{text:'标注练习'});
            }else if(type==4){
                txt=this.$t('WDNPC_WEB_WRONG_EXERCISES',{text:'错题练习'});
            }
            return txt;
        },
        practiceAgain(){
            if((this.practiceDetail.practiceModel===1 || this.practiceDetail.practiceModel===2) && this.practiceDetail.totalCount<1){
                this.$message.warning({message:this.$t('WDNPC_WEB_NO_TEST_QUESTIONS',{text:'暂无试题'}),offset:70})
                return;
            }else if(this.practiceDetail.practiceModel===3 && this.practiceDetail.markCount===0){
                this.$message.warning({message:this.$t('WDNPC_WEB_NO_MARKED_TEST_QUESTIONS',{text:'暂无标记试题'}),offset:70})
                return;
            }else if(this.practiceDetail.practiceModel===4 && this.practiceDetail.errorsCount===0){
                this.$message.warning({message:this.$t('WDNPC_WEB_NO_WRONG_QUESTIONS',{text:'暂无错题'}),offset:70})
                return;
            }
            this.joinExam();
        },
        practiceShow(){
            this.$router.push({
                path:'/practiceTrainingTask',
                query:{
                    practiceId:this.$route.query.practiceId,
                    businessId:this.$route.query.businessId,
                    businessType: this.$route.query.businessType,
                    practiceModel:this.practiceDetail.practiceModel,
                    userPracticeId:this.$route.query.userPracticeId,
                    practiceShow:true,
                    backPath:this.$route.query.backPath,
                    backModule:this.$route.query.backModule,
                }
            })
        },
        joinExam() {
            this.$api.practice.joinExam({
                    params: {
                        businessId: this.$route.query.businessId,
                        businessType: this.$route.query.businessType,
                        practiceId:this.$route.query.practiceId,
                        practiceModel:this.practiceDetail.practiceModel,
                    }
                }).then((res) => {
                if(res.data){
                    this.$router.push({
                        path:'/practiceTrainingTask',
                        query:{
                            practiceId:this.$route.query.practiceId,
                            businessId:this.$route.query.businessId,
                            businessType: this.$route.query.businessType,
                            practiceModel:this.practiceDetail.practiceModel,
                            userPracticeId:res.data,
                            backPath:this.$route.query.backPath,
                            backModule:this.$route.query.backModule,
                        }
                    })
                }
            });
        },
        goBack(){
            this.$router.push({
                path:'/pattern',
                query:{
                    businessId:this.$route.query.businessId,
                    practiceId:this.$route.query.practiceId,
                    businessType:2,
                    backPath:this.$route.query.backPath,
                    backModule:this.$route.query.backModule,
                }
            })
        },
        findUserPaperInfo(){
            this.$api.practice.findUserPaperInfo(this.$route.query.userPracticeId).then((res) => {
                if(res.data){
                    this.practiceDetail = res.data;
                    
                }
            });
        },
    },

    mounted(){
        if (window.history && window.history.pushState) {
            // 往历史记录里面添加一条新的当前页面的url
            history.pushState(null, null, document.URL);
            // 给 popstate 绑定一个方法 监听页面刷新
            window.addEventListener("popstate", this.goBack, false); //false阻止默认事件
        }
        
    },
}

</script>
<style lang="stylus" scoped>
@import '../css/practiceResult.styl'
</style>
