<template>
    <div class='pattern'>
        <div class="backButton" v-if="!theme">
            <span @click="goBack">
                <i class="back el-icon-arrow-left"></i>
                {{$t('WDNPC_WEB_RETURN',{text:'返回'})}}
            </span>
        </div>
        <div class="pattern-title">
            {{patternDetail.practiceName}}
        </div>
        <div class="pattern-list">
            <ul>
                <li @click="gopracticeDetail(1)">
                    <img src="../../../assets/images/exam/sequence.png" alt="">
                    <div class="middle">
                        <h3>{{$t('WDNPC_WEB_SEQUENTIAL_LEARNING',{text:'顺序学习'})}}</h3>
                        <p>{{$t('WDNPC_WEB_ALL_TEST_QUESTIONS_ARE_PRACTICED_IN_SEQUENCE',{text:'全部试题按照顺序进行练习，初次进入练习建议使用顺序练习。'})}}</p>
                    </div>
                    <div class="right">
                        <span class="lastTime">{{$t('WDNPC_WEB_LAST_PRACTICE_TO_THE_FIRST',{text:'上次练习到第'})}}<span>{{patternDetail.commitLastSequence?patternDetail.totalCount: patternDetail.sequence || 0}}</span>{{$t('WDNPC_WEB_QUESTION_ON',{text:'题'})}}&emsp;</span>{{$t('WDNPC_WEB_TOTAL',{text:'共计'})}}{{patternDetail.totalCount}}{{$t('WDNPC_WEB_QUESTION_ON',{text:'题'})}}<svg-icon icon-class="arrow-right"></svg-icon>

                    </div>
                </li>
                <li @click="gopracticeDetail(2)">
                    <img src="../../../assets/images/exam/random.png" alt="">
                    <div class="middle">
                        <h3>{{$t('WDNPC_WEB_RANDOM_PRACTICE',{text:'随机练习'})}}</h3>
                        <p>{{$t('WDNPC_WEB_KNOWLEDGE_POINTS_CROSSPRACTICE_EVERY_TIME_YOU_ENTER',{text:'知识点交叉练习，每次进入练习顺序被打乱，适用对基础知识已有基本掌握。'})}}</p>
                    </div>
                    <div class="right">
                        {{$t('WDNPC_WEB_TOTAL_JI',{text:'共计'})}}{{patternDetail.totalCount || 0}}{{$t('WDNPC_WEB_QUESTION_ON',{text:'题'})}}<svg-icon icon-class="arrow-right"></svg-icon>
                    </div>
                </li>
                <li @click="gopracticeDetail(4)">
                    <img src="../../../assets/images/exam/mistakes.png" alt="">
                    <div class="middle">
                        <h3>{{$t('WDNPC_WEB_NOTEBOOK_FOR_CORRECTION',{text:'错题本'})}}</h3>
                        <p>{{$t('WDNPC_WEB_CONSOLIDATE_EXERCISES_FOR_WRONG_QUESTIONS_AFTER_THE',{text:'针对错题巩固练习，答题正确后，试题将移除错题本。'})}}</p>
                    </div>
                    <div class="right">
                       {{$t('WDNPC_WEB_TOTAL_JI',{text:'共计'})}}{{patternDetail.errorsCount || 0}}{{$t('WDNPC_WEB_QUESTION_ON',{text:'题'})}}<svg-icon icon-class="arrow-right"></svg-icon>
                    </div>
                </li>
                <li @click="gopracticeDetail(3)">
                    <img src="../../../assets/images/exam/remark.png" alt="">
                    <div class="middle">
                        <h3>{{$t('WDNPC_WEB_MY_TAG',{text:'我的标记'})}}</h3>
                        <p>{{$t('WDNPC_WEB_IN_THE_PROCESS_OF_PRACTICE_AFTER_MARKING',{text:'练习过程中对掌握不深刻的试题标记后，试题将进入”我的标记”，可再次回顾练习。'})}}</p>
                    </div>
                    <div class="right">
                        {{$t('WDNPC_WEB_TOTAL_JI',{text:'共计'})}}{{patternDetail.markCount || 0}}{{$t('WDNPC_WEB_QUESTION_ON',{text:"题"})}}<svg-icon icon-class="arrow-right"></svg-icon>
                    </div>
                </li>
            </ul>
        </div>
        <div class="red"></div>
        <el-dialog class="tip-dialog-pattern" :visible.sync="isContinue" width="400px" :close-on-click-modal="false">
            <svg-icon icon-class="tips" class="icon-img"></svg-icon>
            <p class="titles">{{$t('WDNPC_WEB_PROMPT',{text:'提示'})}}</p>
            <p class="text"> {{title}}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="btncancel()">{{ iscancel }}</el-button>
                <el-button type="primary" @click="confirmbtn()" style="width: 120px">{{$t('WDNPC_WEB_OK',{text:'确 定'})}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name:'pattern',
    data () {
        return {
            theme:false,
            patternDetail:{},
            isContinue:false,
            title:'',
            iscancel:'取消',//{text:'取消'})',
            type:null,
        };
    },

    methods: {
        btncancel(){
            if(this.patternDetail.commitLastSequence){
                this.$router.push({
                    path:'/practiceTrainingTask',
                    query:{
                        practiceId:this.$route.query.practiceId,
                        userPracticeId:this.patternDetail.lastSequenceUserPracticeId,
                        businessId:this.$route.query.businessId,
                        businessType: this.$route.query.businessType,
                        practiceModel:1,
                        practiceShow:true,
                        backPath:this.$route.query.backPath,
                        backModule:this.$route.query.backModule,
                    }
                })
            }else{
                this.joinExam();
            }
            this.isContinue=false;
            
        },
        confirmbtn(){
            if(this.patternDetail.commitLastSequence){
                this.joinExam();
            }else{
                this.$router.push({
                    path:'/practiceTrainingTask',
                    query:{
                        practiceId:this.$route.query.practiceId,
                        userPracticeId:this.patternDetail.lastSequenceUserPracticeId,
                        businessId:this.$route.query.businessId,
                        businessType: this.$route.query.businessType,
                        practiceModel:1,
                        sequence:this.patternDetail.sequence,
                        backPath:this.$route.query.backPath,
                        backModule:this.$route.query.backModule,
                    }
                })
            }
            this.isContinue=false;
        },
        joinExam() {
            this.$api.practice.joinExam({
                    params: {
                        businessId: this.$route.query.businessId,
                        businessType: this.$route.query.businessType,
                        practiceId:this.$route.query.practiceId,
                        practiceModel:this.type,
                    }
                }).then((res) => {
                if(res.data){
                    this.$router.push({
                        path:'/practiceTrainingTask',
                        query:{
                            practiceId:this.$route.query.practiceId,
                            businessId:this.$route.query.businessId,
                            businessType: this.$route.query.businessType,
                            practiceModel:this.type,
                            userPracticeId:res.data,
                            backPath:this.$route.query.backPath,
                            backModule:this.$route.query.backModule,
                        }
                    })
                }
            });
        },
        modelInfo(){
            this.$api.practice
                .modelInfo({
                    params: {
                        businessId: this.$route.query.businessId,
                        businessType: this.$route.query.businessType,
                        practiceId:this.$route.query.practiceId
                    }
                })
                .then(res => {
                    if(res.data){
                        this.patternDetail=res.data;
                    }
                });
        },
        gopracticeDetail(type){
            this.type=type;
            if((type===1 || type===2) && this.patternDetail.totalCount<1){
                this.$message.warning({message:this.$t('WDNPC_WEB_NO_TEST_QUESTIONS',{text:'暂无试题'}),offset:70})
                return;
            }else if(type===3 && this.patternDetail.markCount===0){
                this.$message.warning({message:this.$t('WDNPC_WEB_NO_MARKED_TEST_QUESTIONS',{text:'暂无标记试题'}),offset:70})
                return;
            }else if(type===4 && this.patternDetail.errorsCount===0){
                this.$message.warning({message:this.$t('WDNPC_WEB_NO_WRONG_QUESTIONS',{text:'暂无错题'}),offset:70})
                return;
            }
            if(type===1 && this.patternDetail.attendSequence){
                if(this.patternDetail.commitLastSequence){
                    this.title=this.$t('WDNPC_WEB_HAVE_YOU_FINISHED_ALL_THE_PREVIOUS_EXERCISES',{text:'上次练习已全部完成，是否从头练习？'})
                    this.iscancel='查看上次练习',//{text:'查看上次练习'});
                    this.isContinue=true;
                }else{
                    if(this.patternDetail.sequence>0){
                        this.title=this.$t('WDNPC_WEB_LAST_PRACTICE',{text:'上次练到第'})+this.patternDetail.sequence+this.$t('WDNPC_WEB_WHETHER_TO_CONTINUE_THE_PRACTICE',{text:'题，是否继续练习？'})

                        this.isContinue=true;
                    }else{
                        this.$router.push({
                            path:'/practiceTrainingTask',
                            query:{
                                practiceId:this.$route.query.practiceId,
                                userPracticeId:this.patternDetail.lastSequenceUserPracticeId,
                                businessId:this.$route.query.businessId,
                                businessType: this.$route.query.businessType,
                                practiceModel:1,
                                sequence:this.patternDetail.sequence,
                                backPath:this.$route.query.backPath,
                                backModule:this.$route.query.backModule,
                            }
                        })
                    }
                    
                }
                
            }else{
                this.joinExam()
            }


            // else if(type===1 && this.patternDetail.attendSequence && this.patternDetail.sequence==0){
            //     this.$router.push({
            //         path:'/practiceTrainingTask',
            //         query:{
            //             practiceId:this.$route.query.practiceId,
            //             userPracticeId:this.patternDetail.lastSequenceUserPracticeId,
            //             businessId:this.$route.query.businessId,
            //             businessType: this.$route.query.businessType,
            //             practiceModel:1,
            //             sequence:this.patternDetail.sequence,
            //             backPath:this.$route.query.backPath
            //         }
            //     })
            // }
        },
        goBack(){
            if(this.$route.query.backPath){
                this.$router.push({
                    path:this.$route.query.backPath,
                    query:{
                        backModule:this.$route.query.backModule,
                    }
                })
            }else{
                this.$router.push({
                    path:'/learning/taskDetail',
                    query:{
                        validity:true,
                        id:this.$route.query.businessId,
                        backPath:this.$route.query.backPath,
                        type:2
                    }
                })
            }
            
        },
    },

    mounted(){
        this.modelInfo();
    },
}

</script>
<style lang="stylus">
@import '../css/pattern.styl'
</style>
