<template>
  <!-- 练习 -->
  <div>
    <div class="practiceTrainingTask">
      <div class="backButton">
        <span @click="goBack">
          <i class="back el-icon-arrow-left"></i>
        {{$t('WDNPC_WEB_RETURN',{text:'返回'})}}
        </span>
      </div>
      <div class="practiceTrainingTask-con" v-if="showpage">
        <div class="left">
          <div class="testPaper">
            <div class="questions">
              <ul class="cpStOption">
                <div class="subject">
                  <div class="flex-title">
                    <span class="score">{{questionsList[topicIndex].score | grade}}{{$t('WDNPC_WEB_POINTS',{text:'分'})}}</span>
                    <h1 class="subject-title">
                      <span>{{topicIndex + 1}}.</span>
                      <p v-html="questionsList[topicIndex].title" class="questionstem"></p>
                    </h1>
                  </div>
                  <div
                    class="signIcon"
                    @click="markClick(questionsList[topicIndex])"
                  >
                    <span
                      class="mark-btn"
                      :class="{ active: questionsList[topicIndex].mark }"
                    ></span>
                    <span v-if="!questionsList[topicIndex].mark" class="text">{{$t('WDNPC_WEB_MARK',{text:'标记'})}}</span>
                    <span v-else class="text">{{$t('WDNPC_WEB_CANCEL',{text:'取消'})}}</span>
                  </div>
                </div>
                <div class="topicOptions">
                  <el-radio-group v-model="questionsList[topicIndex].userAnswer"
                    v-if="questionsList[topicIndex].type != 2"
                    @change="handleClickOption(questionsList[topicIndex])">
                    <el-radio
                      v-for="(item, index) in questionsList[topicIndex].options"
                      :key="index"
                      :label="item.optionNumber"
                      :disabled="!(!examine && !questionsList[topicIndex].reply)">
                      {{item.optionNumber}}
                      <span v-html="item.optionContent"></span>
                    </el-radio>
                  </el-radio-group>
                  <el-checkbox-group v-model="userAnswerList"
                    v-if="questionsList[topicIndex].type == 2">
                    <el-checkbox
                      v-for="(item, index) in questionsList[topicIndex].options"
                      :key="index"
                      :label="item.optionNumber"
                      :disabled="!(!examine && !questionsList[topicIndex].reply)">
                      {{item.optionNumber}}
                      <span v-html="item.optionContent"></span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </ul>
            </div>
            <div class="btns">
              <div class="submit" :class="[questionsList[topicIndex].userAnswer ? 'answered' : '']" v-if="questionsList[topicIndex].type == 2 &&  !examine" @click="handleClickOption(questionsList[topicIndex])">{{$t('WDNPC_WEB_SUBMIT_ANSWER',{text:'提交答案'})}}</div>
              <div class="prev" :class="[topicIndex == 0 ? 'noClick' : '']" @click="prevQuestion">{{$t('WDNPC_WEB_PREVIOUS_QUESTION',{text:'上一题'})}}</div>
              <div class="next" :class="[topicIndex == questionsList.length - 1 ? 'noClick' : '']" @click="nextQuestion">{{$t('WDNPC_WEB_NEXT_QUESTION',{text:'下一题'})}}</div>
            </div>
            <div class="analysis" v-if="!(!examine && !questionsList[topicIndex].userAnswer)">
              <div class="answer">
                <span class="correct">{{$t('WDNPC_WEB_CORRECT_ANSWER',{text:'正确答案：'})}}{{questionsList[topicIndex].rightAnswer}}</span>
                <span :class="[questionsList[topicIndex].userAnswer == questionsList[topicIndex].rightAnswer ? 'correct' : 'respondence']">{{$t('WDNPC_WEB_YOUR_ANSWER',{text:'您的答案：'})}}{{questionsList[topicIndex].userAnswer || $t('WDNPC_WEB_NO_ANSWER',{text:'未做答'})}}</span>

              </div>
              <div class="analyse">
                <div class="title">{{$t('WDNPC_WEB_ANSWER_PARSING',{text:'答案解析'})}}</div>
                <div class="con"  v-html="questionsList[topicIndex].remark || $t('WDNPC_WEB_NONE',{text:'无'})"></div>
              </div>
            </div>
          </div>
        </div>
        <div :class="['right', examine ? 'examine-num' : '']">
          <div class="topCard" v-if="examine">
            <div class="scantron">
              <div class="titles">
                <svg-icon icon-class="exam-static"></svg-icon>
                {{$t('WDNPC_WEB_PRACTICE_ANALYSIS',{text:'练习分析'})}}
              </div>
            </div>
            <div class="chart">
              <div class="exam-results-content1">
                <!--交卷完成列表-->
                <div class="exam-results-circle">
                  <gauge :rate="examDetail.rightRate">
                    <template v-slot:txt>
                      <p
                        class="exam-results-porgress"
                        :style="`color:${numColor}`"
                      >
                        {{ examDetail.rightRate }}<span>%</span>
                      </p>
                      <p class="exam-num-tips1">{{$t('WDNPC_WEB_ACCURACY',{text:'正确率'})}}</p>
                    </template>
                  </gauge>
                </div>
                <div class="exam-results-ulbox">
                  <div class="finish">
                    <span class="dot"></span>{{$t('WDNPC_WEB_ANSWER_RIGHT',{text:'答对'})}}{{$t('WDNPC_WEB_MAOHAO',{text:'：'})}}<span class="num">{{ examDetail.rightCount }}</span>{{$t('WDNPC_WEB_DAO_ON',{text:'道'})}}
                  </div>
                  <div class="wrong">
                    <span class="dot"></span>答错：<span class="num">{{ examDetail.errorCount }}</span>{{$t('WDNPC_WEB_DAO_ON',{text:'道'})}}
                  </div>
                  <div class="none">
                    <span class="dot"></span>未答：<span class="num">{{ examDetail.emptyCount }}</span>{{$t('WDNPC_WEB_DAO_ON',{text:'道'})}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scantron">
            <div class="titles">
              <svg-icon icon-class="datika"></svg-icon>
              {{$t('WDNPC_WEB_ANSWER_CARD',{text:'答题卡'})}}
            </div>
            <div class="type">
              <span class="finish"><span></span>{{$t('WDNPC_WEB_ANSWER_RIGHT',{text:'答对'})}}</span>
              <span class="wrong"><span></span>{{$t('WDNPC_WEB_WRONG_ANSWER',{text:'答错'})}}</span>
              <span class="none"><span></span>{{$t('WDNPC_WEB_NOT_ANSWEREDS',{text:'未答'})}}</span>
            </div>
          </div>
          <div
            ref="stCount"
            class="answer-num"
          >
            <div
              v-for="(mark, index) in answerSheetList"
              :key="mark.id"
              class="list"
            >
              <h1 v-if="mark.questionList.length > 0">
                {{
                  mark.type == 1
                    ? $t('WDNPC_WEB_SINGLE_CHOICE',{text:'单选题'})
                    : mark.type == 2
                    ? $t('WDNPC_WEB_MULTIPLE_TOPICS',{text:'多选题'})
                    : mark.type == 3
                    ? $t('WDNPC_WEB_JUDGMENT_QUESTIONS',{text:'判断题'})
                    : $t('WDNPC_WEB_ADVANCED_QUESTIONS',{text:'高阶题'})
                }}
                <span>{{$t('WDNPC_WEB_LEFT_KUO_HAO',{text:'（'})}}{{$t('WDNPC_WEB_TOTAL_ON',{text:'共'})}}{{mark.questionList.length}}{{$t('WDNPC_WEB_QUESTION',{text:'题）'})}}</span>

              </h1>
              <a
                v-for="(item, index1) in mark.questionList"
                :key="index1"
                :class="['pointer', !item.userAnswer ? 'unselected' : item.userAnswer == item.rightAnswer ? 'face' : 'error', item.questionId == active ? 'active' : '',item.mark?'question-mark':'']"
                @click.prevent="anchor(item.questionId)"
              >
                <span v-if="index == 1">
                  {{
                    answerSheetList[index - 1].questionList.length + index1 + 1
                  }}
                </span>
                <span v-else-if="index == 2">
                  {{
                    answerSheetList[index - 2].questionList.length +
                    answerSheetList[index - 1].questionList.length +
                    index1 +
                    1
                  }}
                </span>
                <span v-else-if="index == 3">
                  {{
                    answerSheetList[index - 3].questionList.length +
                    answerSheetList[index - 2].questionList.length +
                    answerSheetList[index - 1].questionList.length +
                    index1 +
                    1
                  }}
                </span>
                <span v-else>{{ index1 + 1 }}</span>
              </a>
            </div>
          </div>
          <div class="answer-btn" v-if="!examine">
            <div class="btn" @click="submitExam">{{$t('WDNPC_WEB_HAND_IN_THE_DOCUMENTS_IMMEDIATELY',{text:'马上交卷'})}}</div>
          </div>
          <div class="setUp" v-if="examine">
            <div class="setUp-scantron">
              <div class="titles">
                <svg-icon icon-class="exam-set"></svg-icon>
                {{$t('WDNPC_WEB_SETTINGS',{text:'设置'})}}
              </div>
              <el-switch
                v-model="questionSwitch"
                @change="changeSwitch($event)"
                :inactive-text="$t('WDNPC_WEB_ONLY_CHECK_THE_WRONG_QUESTIONS_AND_UNANSWERED',{text:'只看查看错题和未答题'})">
              </el-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="tip-dialog"
      :visible.sync="coursedialogVisible"
      width="400px"
      :before-close="handleClose"
    >
      <svg-icon class="icon-img" icon-class="tips"></svg-icon>
      <p class="titles">{{$t('WDNPC_WEB_PROMPT',{text:'提示'})}}</p>
      <p class="text" v-if="complete">{{$t('WDNPC_WEB_WHETHER_TO_HAND_IN_DOCUMENTS',{text:'是否要进行交卷？'})}}</p>
      <p class="text" v-else>
        {{$t('WDNPC_WEB_YOU_ALSO_HAVE',{text:'您还有'})}}<span>{{ remainingNum }}</span
        >{{$t('WDNPC_WEB_NO_ANSWER_TO_THE_QUESTION_CONFIRMED_SUBMISSION',{text:'道题目未作答，确认交卷？'})}}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="leftBtn()">{{ cancelText }}</el-button>
        <el-button type="primary" @click="rightBtn()">{{
          submitText
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import gauge from './gauge.vue'
export default {
  name: "practiceTrainingTask",
  show: true,
  components: {
    gauge
  },
  data() {
    return {
      showpage: false,
      coursedialogVisible: false,
      cancelText: "",
      submitText: "",
      remainingNum: null,
      complete: false,
      examDetail: {},
      stList: 0,
      questionsList: [], // 题目列表
      answerSheetList: [], // 答题卡列表
      saveData: {
        practiceId: "", // 试卷id
        commitTime: "", // 提交时间
      },
      userPracticeId: this.$route.query.userPracticeId,
      practiceId: this.$route.query.practiceId,
      businessId: this.$route.query.businessId,
      businessType: this.$route.query.businessType,
      practiceModel: this.$route.query.practiceModel,
      sequence: Number(this.$route.query.sequence),// 上次答题最后一题的序号
      practiceShow: this.$route.query.practiceShow, // true 查看 false 答题
      topicIndex: null,
      cacheTopicIndex: null,
      numColor: '',
      active: null,
      userPaperCommitDto: {}, // 单次提交接口参数
      userAnswerList: [],
      questionSwitch: false,
      cacheQuestionsList: [], // 题目缓存
      cacheAnswerSheetList: [], // 答题卡缓存
      examine: false
    };
  },
  filters: {
    grade(msg){
      if (msg%1 === 0) {
        return msg+'.0'
      } else {
        return msg.toFixed(1)
      }
    },
  },
  destroyed() {
    sessionStorage.removeItem("questionsList");
    sessionStorage.removeItem("answerSheetList");
    sessionStorage.removeItem("topicIndex");
  },
  mounted() {
    this.cacheQuestionsList = JSON.parse(sessionStorage.getItem("questionsList"))
    this.cacheAnswerSheetList = JSON.parse(sessionStorage.getItem("answerSheetList"))
    this.cacheTopicIndex = JSON.parse(sessionStorage.getItem("topicIndex"))
  },
  created() {
    if (this.practiceShow) {
      // 查看
      this.findUserPaperInfo();
      this.examine = true;
    } else {
      // 答题
      this.findQuestForExam();
      this.examine = false;
    }
    this.returnButton();
  },
  methods: {
    // 只看错题
    changeSwitch(val) {
      if (val) {
        var have = this.questionsList.some((ele)=>{
          if (ele.userAnswer != ele.rightAnswer) {
            return true;
          }
        })
        if (have) {
          for (var i = 0; i < this.questionsList.length; i++) {
            if (this.questionsList[i].userAnswer != this.questionsList[i].rightAnswer) {
              this.topicIndex = i;
              this.assignment();
              return;
            }
          }
        } else {
          this.questionSwitch = false;
          this.$message({
            message: this.$t('WDNPC_WEB_ALL_ANSWERS_ARE_CORRECT_NO_ANSWERS_NO',{text:'全部作答正确，无答错、未答'}),
            type: 'warning'
          });
        }
      } else {
        this.topicIndex = 0;
        this.assignment();
      }
    },
    // 点击标记
    markClick(question) {
      const question1 = question;
      this.$nextTick(() => {
        question1.mark = !question1.mark;
        this.questionMark();
      });

    },
    assignment() {
      this.active = this.questionsList[this.topicIndex].questionId;
      this.userAnswerList = this.questionsList[this.topicIndex].userAnswer ? this.questionsList[this.topicIndex].userAnswer.split(',') : [];
    },
    // 下一题
    nextQuestion() {
      if (this.topicIndex < this.questionsList.length - 1) {
        if (this.questionSwitch) {
          var newQuestionsList = this.questionsList.slice(this.topicIndex+1, this.questionsList.length);
          var have = newQuestionsList.some((ele)=>{
              if (ele.userAnswer != ele.rightAnswer) {
                  return true;
              }
          })
          if (have) {
            for (var i = this.topicIndex+1; i < this.questionsList.length; i++) {
              if (this.questionsList[i].userAnswer != this.questionsList[i].rightAnswer) {
                this.topicIndex = i;
                this.assignment();
                return;
              }
            }
          } else {
            this.$message({
              message: this.$t('WDNPC_WEB_NO_WRONG_QUESTIONS_YET_ANSWERED',{text:'暂无错题、未答题'}),
              type: 'warning'
            });
          }
        } else {
          this.topicIndex++;
          this.assignment();
        }
        sessionStorage.setItem("topicIndex", JSON.stringify(this.topicIndex));
      } else {
        if (this.questionSwitch) {
          this.$message({
            message: this.$t('WDNPC_WEB_NO_WRONG_QUESTIONS_YET_ANSWERED',{text:'暂无错题、未答题'}),
            type: 'warning'
          });
        }
      }
    },
    // 上一题
    prevQuestion() {
      if (this.topicIndex > 0) {
        if (this.questionSwitch) {
          var newQuestionsList = this.questionsList.slice(0, this.topicIndex);
          var have = newQuestionsList.some((ele)=>{
              if (ele.userAnswer != ele.rightAnswer) {
                  return true;
              }
          })
          if (have) {
            for (var i = this.topicIndex-1; i >= 0; i--) {
              if (this.questionsList[i].userAnswer != this.questionsList[i].rightAnswer) {
                this.topicIndex = i;
                this.assignment();
                return;
              }
            }
          } else {
            this.$message({
              message: this.$t('WDNPC_WEB_NO_WRONG_QUESTIONS_YET_ANSWERED',{text:'暂无错题、未答题'}),
              type: 'warning'
            });
          }
        } else {
          this.topicIndex--;
          this.assignment();
        }
        sessionStorage.setItem("topicIndex", JSON.stringify(this.topicIndex));
      } else {
        if (this.questionSwitch) {
          this.$message({
            message: this.$t('WDNPC_WEB_NO_WRONG_QUESTIONS_YET_ANSWERED',{text:'暂无错题、未答题'}),
            type: 'warning'
          });
        }
      }
    },
    // 单次提交答案
    commitPaper() {
      this.getASingleAnswer();
      this.$api.practice.commitPaper(this.userPaperCommitDto).then(data => {
        console.log('data', data)
        sessionStorage.setItem("topicIndex", JSON.stringify(this.topicIndex));
        sessionStorage.setItem("questionsList", JSON.stringify(this.questionsList));
        sessionStorage.setItem("answerSheetList", JSON.stringify(this.answerSheetList));
        if (this.questionsList[this.topicIndex].userAnswer == this.questionsList[this.topicIndex].rightAnswer) {
          this.nextQuestion();
        }
      })
    },
    // 标记接口
    questionMark() {
      const mark = this.questionsList[this.topicIndex].mark ? 1 : 0;
      const questionId = this.questionsList[this.topicIndex].questionId;
      this.$api.practice.questionMark(this.businessId, this.businessType, mark, this.practiceId, questionId).then(data => {
        console.log('data', data)
      })
    },
    getASingleAnswer() {
      const that = this;
      this.userPaperCommitDto = {};
      this.userPaperCommitDto.commitTime = this.dateFormat();
      this.userPaperCommitDto.practiceId = that.userPracticeId;
      this.userPaperCommitDto.answerList = [{
        answer: this.questionsList[this.topicIndex].userAnswer,
        mark: this.questionsList[this.topicIndex].mark ? 1 : 0,
        questionId: this.questionsList[this.topicIndex].questionId,
        questionType: this.questionsList[this.topicIndex].type,
      }]
    },
    // 单选 判断
    handleClickOption(question) {
      this.$nextTick(() => {
        if (!question.reply) {
          let userAnswer = '';
          if (question.type == 2) {
            if (this.userAnswerList.length) {
              userAnswer = this.userAnswerList.sort().join(',');
            } else {
              userAnswer = '';
            }
          } else {
            userAnswer = this.questionsList[this.topicIndex].userAnswer;
          }
          if (userAnswer) {
            this.questionsList.forEach(ele => {
              if (ele.questionId == question.questionId) {
                ele.userAnswer = userAnswer;
                ele.reply = true;
              }
            })
            this.answerSheetList.forEach(ele => {
              ele.questionList.forEach(item => {
                if (item.questionId == question.questionId) {
                  item.userAnswer = userAnswer;
                  item.reply = true;
                }
              })
            })
            this.commitPaper()
          } else {
            this.$message({
              message: this.$t('WDNPC_WEB_PLEASE_SELECT',{text:'请选择'}),
              type: 'warning'
            });
          }
        }
      })
    },
    // 获取题目
    findQuestForExam() {
      this.$api.practice.findQuestForExam(this.userPracticeId).then((res) => {
        if (res.data) {
          this.examDetail = res.data;
          if (!this.cacheQuestionsList) {
            this.questionsList = res.data.questions;
            this.formatting(this.questionsList);
          } else {
            this.topicIndex = Number(this.cacheTopicIndex);
            this.questionsList = this.cacheQuestionsList;
            this.answerSheetList = this.cacheAnswerSheetList;
            if (this.questionsList.length > 0) {
              this.showpage = true;
            }
          }
        }
      });
    },
    // 查看
    findUserPaperInfo() {
      this.$api.practice.findUserPaperInfo(this.userPracticeId).then((res) => {
        if(res.data){
          this.examDetail = res.data;
          this.questionsList = this.examDetail.examQuestionInfos;
          this.formatting(this.questionsList);
          this.examDetail.rightRate = Number(this.examDetail.rightRate);
          if (this.examDetail.rightRate>=50) {
            this.numColor = '#415FF6';
          } else {
            this.numColor = '#FFA132';
          }
        }
      });
    },
    formatting(data) {
      if (data.length > 0) {
        this.showpage = true;
        if (this.sequence) {
          if (this.sequence < data.length) {
            this.topicIndex = this.sequence;
          } else {
            this.topicIndex = this.sequence - 1;
          }
        } else {
          this.topicIndex = 0;
        }
        if (data[this.topicIndex].type == 2) {
          this.userAnswerList = data[this.topicIndex].userAnswer ? data[this.topicIndex].userAnswer.split(',') : [];
        }
        this.active = data[this.topicIndex].questionId;
        var subjectList = [];
        if (this.practiceShow) {
          this.stList = this.examDetail.examQuestionInfos.length;
          subjectList = this.examDetail.examQuestionInfos;
        } else {
          this.stList = this.examDetail.questions.length;
          subjectList = this.examDetail.questions;
        }
        this.answerSheetList = [];
        subjectList.forEach((item)=>{
          if (this.answerSheetList.length == 0) {
            this.answerSheetList.push({
              totalMark: '',
              type: item.type,
              questionList: [
                item
              ]
            })
          } else {
            var have = this.answerSheetList.some((ele)=>{
              if (ele.type == item.type) {
                return true;
              }
            })
            if (have) {
              this.answerSheetList.forEach((ele, index1)=>{
                if (ele.type == item.type) {
                  this.answerSheetList[index1].questionList.push(item);
                }
              })
            } else {
              this.answerSheetList.push({
                totalMark: '',
                type: item.type,
                questionList: [
                  item
                ]
              })
            }
          }
        })
      }
    },
    // 点击题号锚点
    anchor(anchorName) {
      this.active = anchorName;
      this.questionsList.forEach((ele, index) => {
        if (ele.questionId == anchorName && anchorName != this.questionsList[this.topicIndex].questionId) {
          this.topicIndex = index;
          sessionStorage.setItem("topicIndex", JSON.stringify(this.topicIndex));
          this.userAnswerList = this.questionsList[this.topicIndex].userAnswer ? this.questionsList[this.topicIndex].userAnswer.split(',') : [];
        }
      })
    },
    // 浏览器回退按钮到训练营
    returnButton() {
      if (this.$route.name == "practiceTrainingTask") {
        let that = this;
        if (window.history && window.history.pushState) {
            window.onpopstate = function() {
              window.history.pushState('forward', null, '#');
              window.history.forward(1);
              that.goBack();
            }
        }
        window.history.pushState('forward', null, '#'); //在IE中必须得有这两行
        window.history.forward(1);
      }
    },
    // 返回
    goBack() {
      this.$router.push({
        path: "/pattern",
        query: {
          businessId: this.businessId,
          practiceId: this.practiceId,
          businessType: this.businessType,
          backPath: this.$route.query.backPath,
          backModule:this.$route.query.backModule,
        },
      });
    },
    leftBtn() {
      if (this.complete) {
        this.handleClose();
      } else {
        this.commitAllPaper();
      }
    },
    rightBtn() {
      if (this.complete) {
        this.commitAllPaper();
      } else {
        this.handleClose();
      }
    },
    // 提交
    submitExam() {
      let num = 0;
      this.questionsList.forEach((val) => {
        if (val.reply) {
          num += 1;
        }
      });
      this.coursedialogVisible = true;
      if (num === Number(this.stList)) {
        this.complete = true;
        this.cancelText = this.$t('WDNPC_WEB_CONTINUE_TO_ANSWER_QUESTIONS',{text:'继续答题'});
        this.submitText = this.$t('WDNPC_WEB_SUBMIT',{text:'提交'});
      } else {
        this.complete = false;
        this.remainingNum = Number(this.stList) - num;
        this.cancelText = this.$t('WDNPC_WEB_SUBMIT',{text:'提交'});
        this.submitText = this.$t('WDNPC_WEB_CONTINUE_TO_ANSWER_QUESTIONS',{text:'继续答题'});
      }
    },
    // 关闭弹框
    handleClose() {
      this.coursedialogVisible = false;
    },
    // 提交练习
    commitAllPaper() {
      this.coursedialogVisible = false;
      // this.getPaperList();
      this.saveData.commitTime = this.dateFormat();
      this.saveData.practiceId = this.userPracticeId;
      this.$api.practice.commitAllPaper(this.saveData).then((res) => {
        if (res.data) {
          this.$router.push({
            path: "/practiceResult",
            query: {
              businessId: this.businessId,
              businessType: this.businessType,
              practiceId: this.practiceId,
              practiceModel: this.practiceModel,
              userPracticeId: this.userPracticeId,
              backPath: this.$route.query.backPath,
              backModule:this.$route.query.backModule,
            },
          });
        }
      });
    },
    // 试卷信息
    // getPaperList() {
      // this.saveData.commitTime = this.dateFormat();
      // this.saveData.answerList = [];
      // this.saveData.practiceId = this.userPracticeId;
      // this.questionsList.forEach((item) => {
      //   const item1 = item;
      //   if (item1.userAnswer && item1.userAnswer.length > 1) {
      //     let userAnswer = item1.userAnswer.split(",");
      //     item1.userAnswer = Array.from(userAnswer).sort().join(",");
      //   }
      //   if (item1.userAnswer !== undefined) {
      //     this.saveData.answerList.push({
      //       answer: item1.userAnswer,
      //       mark: item1.mark,
      //       questionId: item1.questionId,
      //       questionType: item1.type,
      //     });
      //   }
      // });
    // },
    // 时间转换
    dateFormat() {
      const date = new Date();
      const year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      const month =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1;
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const hours =
        date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
      const minutes =
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      const seconds =
        date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
      // 拼接
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '../css/practiceTrainingTask.styl';
</style>
