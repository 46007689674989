<template>
    <!-- 练习 -->
    <div>
        <div class="practice">
            <div class="backButton">
                <span @click="goBack">
                        <i class="back el-icon-arrow-left"></i>
                   {{ $t('WDNPC_WEB_RETURN',{text:'返回'})}}</span>
            </div>
            <div class="practice-con">
                <div class="left">
                    <div class="title">
                        <span class="line"></span>
                        <h4 :title="examDetail.name">{{examDetail.name | ellipsis}}</h4>
                    </div>
                    <div class="testPaper">
                        <div class="item" :id="'s' + item.questionId" v-for="(item, index) in questionsList" :key="index">
                            <div class="stem">
                                <h4>{{index + 1}}.【
                                    {{
                                      item.type == 1 
                                      ? $t('WDNPC_WEB_SINGLE_CHOICE',{text:'单选题'}) 
                                      : item.type == 2 
                                      ? $t('WDNPC_WEB_MULTIPLE_TOPICS',{text:'多选题'}) 
                                      : item.type == 3 
                                      ? $t('WDNPC_WEB_JUDGMENT_QUESTIONS',{text:'判断题'}) 
                                      : item.type == 4 
                                      ? $t('WDNPC_WEB_ADVANCED_QUESTIONS',{text:'高阶题'}) 
                                      : item.type == 6
                                      ? '填空题'
                                      : ''
                                      }} / {{item.score}}{{$t('WDNPC_WEB_POINTS',{text:'分'})}}



                                    】<span v-html="item.title">{{item.title}}</span></h4>
                                <li v-for="(item1, index1) in item.options" :key="index1">{{item1.optionNumber}}<span v-html="item1.optionContent">{{item1.optionContent}}</span></li>
                            </div>
                            <div class="option">
                                <div v-if="item.type !== 6">
                                    <li class="answer"
                                        v-for="(item1, index1) in item.options"
                                        :key="index1"
                                        @click="
                                            handleClickOption(
                                                item,
                                                item.options,
                                                item.type,
                                                item1,
                                                index,
                                                index1
                                            )
                                        ">
                                        <span
                                            v-if="item.type!=2"
                                            class="option-btn"
                                            :class="{
                                                'active': item1.isAnswers
                                            }"
                                        />
                                        <span
                                            v-if="item.type==2"
                                            class="el-checkbox__input"
                                            :class="{
                                                'is-checked': item1.isAnswers
                                            }">
                                            <span class="el-checkbox__inner"></span>
                                        </span>
                                        <span class="option-text">{{item1.optionNumber}}</span>
                                    </li>
                                </div>
                                <div v-if="item.type === 6" class="completionBox">
                                    <div class="Completion-item" v-for="(completion,completionIndex) in item.completionAnswer" :key="'comple'+completionIndex">
                                        <span class="label">第{{completionIndex+1}}空：</span>
                                        <el-input
                                            class="CompletionAnswers"
                                            v-model="completion.answer"
                                            placeholder="请输入答案"
                                            @change="(val) => getop3(val,item)"
                                        ></el-input>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="scantron">
                        <div class="titles">{{$t('WDNPC_WEB_ANSWER_CARD',{text:'答题卡'})}}</div>
                        <div class="type">
                            <span class="finish"><span></span>{{$t('WDNPC_WEB_ANSWERED',{text:'已答'})}}</span>
                            <span class="none"><span></span>{{$t('WDNPC_WEB_NOT_ANSWEREDS',{text:'未答'})}}</span>
                        </div>
                    </div>
                    <div :class="['answer-num', winH==768?'min-answer-num':'']">
                        <span v-for="(item, index) in questionsList" :key="index"
                            :class="['pointer', item.result ? 'ydt' : '']"
                            @click.prevent="anchor('s' + item.questionId)">
                            {{index + 1}}
                        </span>
                    </div>
                    <div class="answer-btn">
                        <div class="btn" @click="submitExam">{{$t('WDNPC_WEB_SUBMIT',{text:'提交'})}}</div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            class="tip-dialog"
            :visible.sync="coursedialogVisible"
            width="400px"
            :before-close="handleClose">
            <svg-icon icon-class="tips" class="icon-img"></svg-icon>
            <p class="titles">{{$t('WDNPC_WEB_PROMPT',{text:'提示'})}}</p>
            <p class="text" v-if="complete">{{$t('WDNPC_WEB_WHETHER_TO_HAND_IN_DOCUMENTS',{text:'是否要进行交卷？'})}}</p>
            <p class="text" v-else>{{$t('WDNPC_WEB_YOU_STILL_HAVE',{text:'你还有'})}}<span>{{remainingNum}}</span>{{$t('WDNPC_WEB_NO_ANSWER_TO_THE_QUESTION_CONFIRMED_SUBMISSION',{text:'道题目未作答，确认交卷？'})}}</p>

            <span slot="footer" class="dialog-footer">
                <el-button @click="leftBtn()">{{cancelText}}</el-button>
                <el-button type="primary" @click="rightBtn()">{{submitText}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'practice',
    show: true,
    components: {},
    data() {
        return {
            coursedialogVisible: false,
            cancelText:'',
            submitText:'',
            assessUserVo: '',
            assessUserPaperResultVo: {},
            remainingNum: null,
            complete: false,
            examDetail: {},
            stList: 0,
            questionsList: [],
            saveData: {
                answerList: [], // 本试卷答题信息列表
                practiceId: '', // 试卷id
                commitTime: '', // 提交时间
            },
            userPracticeId: '',
            practiceId: this.$route.query.practiceId,
            // practiceId: 6,
            winH: window.screen.height,
        };
    },
    filters: {
        ellipsis(msg){
            if(msg && msg.length>34){
            return msg.substr(0, 34)+'...'
            }
            return msg
        }
    },
    mounted() {},
    created() {
        this.joinExam();
        this.returnButton();
    },
    methods: {
        // 填空题
        getop3(val,item){
            console.log('val, question------>',val, item)
            let num = 0;
            item.completionAnswer.forEach(item => {
                if(item.answer){
                    num++;
                }
            })
            console.log(num,'num===')
            if(num){
              item.result = true;
            } else {
              item.result = false;
            }
        },
        // 选择          题目        选项集合    类型   选中项  题目   选项
        handleClickOption(question, optionList, type, item, index, index2) {
            const item1 = item;
            const myanswer = []; // 多选题答案
            /* type  1单选  2多选  3判断   4高阶*/
            if (type !== 2) {
                optionList.forEach((item2, index3) => {
                    const item4 = item2;
                    if (index2 === index3) {
                        this.$set(item1, "isAnswers", true);
                        this.$set(question, "myAnswers", item1.optionNumber);
                    } else {
                        this.$set(item4, "isAnswers", false);
                    }
                });
                question.result = true;
            } else {
                if (!item1.isAnswers) {
                    this.$set(item1, "isAnswers", true);
                } else {
                    this.$set(item1, "isAnswers", false);
                }
                optionList.forEach(item2 => {
                    if (item2.isAnswers) {
                        myanswer.push(item2.optionNumber);
                    }
                });
                this.$set(question, "myAnswers", myanswer.join(","));
                if (myanswer.length > 0) {
                    question.result = true;
                } else {
                    question.result = false;
                }
            }
            // 获取已答题数目
            this.changeNum = 0;
            this.questionsList.forEach(item=>{
                if (item.myAnswers) {
                    this.changeNum++;
                }
            })
        },
        joinExam() {
            const params = {
                businessType:3,
                businessId:this.$route.query.courseId || '',
                practiceId:this.practiceId,
            }
            this.$api.practice.joinExam({params}).then((res) => {
                if(res.data){
                    this.userPracticeId = res.data;
                    this.findQuestForExam();
                }
            });
        },
        async findQuestForExam() {
            await this.$api.practice.findQuestForExam(this.userPracticeId).then((res) => {
                if(res.data){
                    this.examDetail = res.data;
                    
                    // 解决填空题每空绑定的值
                    res.data.questions.forEach(item => {
                        if(+item.type === 6){
                            if(!item.completionAnswer){
                                this.$set(item,'completionAnswer',[])
                                // item.completionAnswer = [];
                                for(let i = 0; i < item.completeInputNum ; i++){
                                      item.completionAnswer.push({
                                          answer:''
                                      })
                                } 
                            }
                        }
                    })

                    this.questionsList = res.data.questions;
                    
                    this.stList = this.examDetail.questions.length;
                    console.log(this.questionsList,'questionsList===');
                    // this.$forceUpdate()


                }
            });
        },
        // 点击题号锚点
        anchor(anchorName) {
            const anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        },
        // 浏览器回退按钮到训练营
        returnButton(){
            const that = this;
            if (this.$route.name == 'wrongTopic') {
                if (window.history && window.history.pushState) {
                    window.onpopstate = function() {
                        window.history.pushState('forward', null, '#');  
                        window.history.forward(1);
                        that.goBack();
                    }
                }
                window.history.pushState('forward', null, '#'); //在IE中必须得有这两行  
                window.history.forward(1);
            }
        },

        // 返回
        goBack() {
            if (this.$route.query.pathName=='/video') {
                this.$router.push({
                    path: this.$route.query.pathName,
                    query: {
                        trainId:this.$route.query.trainId,
                        courseId:this.$route.query.courseId,
                        detailId:this.$route.query.detailId,
                        studyProcess:this.$route.query.studyProcess,
                        courseName:this.$route.query.courseName,
                        taskType:this.$route.query.taskType || null,
                        backPathName: this.$route.query.backPathName
                    }
                })
            } else if(this.$route.query.pathName=='/course/play'){
                // 如果是从培训任务，课程中的单元练习跳转过来，跳转回培训任务课程列表页面
                console.log('sadasdasdasdasdasdasdas',this.$route.query);
                this.$router.push({
                    path: this.$route.query.backPathName,
                    query: {
                        backPath: this.$route.query.backPath,
                        id: this.$route.query.id,
                        taskId: this.$route.query.taskId || this.$route.query.trainId,
                        taskType: this.$route.query.taskType,
                        backMoudle: this.$route.query.backMoudle
                    }
                })
            }else {
                this.$router.push({
                    path: this.$route.query.pathName,
                    query: {
                        taskType:this.$route.query.taskType || null,
                        id:this.$route.query.id,
                        taskId: this.$route.query.taskId || this.$route.query.trainId,
                        backModule:this.$route.query.backMoudle,
                        backPath: this.$route.query.backPath,
                    }
                })
            }
        },
        leftBtn() {
            if (this.complete) {
                this.handleClose();
            } else {
                this.commitAllPaper();
            }
        },
        rightBtn() {
            if (this.complete) {
                this.commitAllPaper();
            } else {
                this.handleClose();
            }
        },
        // 提交
        submitExam() {
            let num = 0;
            this.questionsList.forEach(val => {
                if (val.result) {
                    num += 1;
                }
            });
            this.coursedialogVisible = true;
            if (num === Number(this.stList)) {
                this.complete = true;
                this.cancelText = this.$t('WDNPC_WEB_CONTINUE_TO_ANSWER_QUESTIONS',{text:'继续答题'});
                this.submitText = this.$t('WDNPC_WEB_SUBMIT',{text:'提交'});
            } else {
                this.complete = false;
                this.remainingNum = Number(this.stList) - num;
                this.cancelText = this.$t('WDNPC_WEB_SUBMIT',{text:'提交'});
                this.submitText =this.$t('WDNPC_WEB_CONTINUE_TO_ANSWER_QUESTIONS',{text:'继续答题'});
            }
        },
        // 关闭弹框
        handleClose () {
            this.coursedialogVisible = false;
        },
        // 提交练习
        commitAllPaper() {
            this.coursedialogVisible = false;
            this.getPaperList();
            this.$api.practice.commitAllPaper(this.saveData).then((res) => {
                if(res.data){
                    if (this.$route.query.pathName=='/video') {
                        this.$router.push({
                            path: '/wrongTopic',
                            query: {
                                practiceId: this.practiceId,
                                userPracticeId: this.userPracticeId,
                                trainId:this.$route.query.trainId,
                                courseId:this.$route.query.courseId,
                                detailId:this.$route.query.detailId,
                                studyProcess:this.$route.query.studyProcess,
                                courseName:this.$route.query.courseName,
                                taskType:this.$route.query.taskType || null,
                                pathName:this.$route.query.pathName,
                                backPathName: this.$route.query.backPathName
                            }
                        })
                    } else {
                        this.$router.push({
                            path: '/wrongTopic',
                            query: {
                                practiceId: this.practiceId,
                                userPracticeId: this.userPracticeId,
                                pathName:this.$route.query.pathName,
                                taskType:this.$route.query.taskType || null,
                                id:this.$route.query.id,
                                taskId: this.$route.query.taskId,
                                backMoudle:this.$route.query.backMoudle,
                                backPathName: this.$route.query.backPathName
                            }
                        })
                    }
                }
            });
        },
        // 试卷信息
        getPaperList() {
            this.saveData.commitTime = this.dateFormat();
            this.saveData.answerList = [];
            this.saveData.practiceId = this.userPracticeId;
            this.questionsList.forEach(item => {
                const item1 = item;
                let myAnswers = [];
                console.log(item1,'item1===')
                if(item1.type === 6){

                    let num = 0;
                    item1.completionAnswer.forEach(completionItem => {
                        if(completionItem.answer !== ""){
                            num ++ ;
                        }
                        myAnswers.push(completionItem.answer)
                    })
                    if(num){
                    item1.myAnswers = myAnswers.join(',')
                    }
                }
                if (item1.myAnswers && item1.myAnswers.length > 1 && item1.type !== 6) {
                    let userAnswer = item1.myAnswers.split(",");
                    item1.myAnswers = Array.from(userAnswer)
                        .sort()
                        .join(",");
                }
                if (item1.myAnswers !== undefined) {
                    this.saveData.answerList.push({
                        answer: item1.myAnswers,
                        questionId: item1.questionId,
                        questionType: item1.type,
                    });
                }
            });
        },
        // 时间转换
        dateFormat() {
            const date = new Date();
            const year = date.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            const month =
                date.getMonth() + 1 < 10
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1;
            const day =
                date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const hours =
                date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
            const minutes =
                date.getMinutes() < 10
                    ? `0${date.getMinutes()}`
                    : date.getMinutes();
            const seconds =
                date.getSeconds() < 10
                    ? `0${date.getSeconds()}`
                    : date.getSeconds();
            // 拼接
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
    }
};
</script>
<style lang="stylus" scoped>
@import "../css/practice.styl"
</style>
