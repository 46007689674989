<template>
    <div class="parent-element-center">
        <div class="svg-wrap">
            <svg :width="diameterShow" :height="diameterShow" :viewbox="viewbox">
                <linearGradient id="svg-gradient" gradientUnits="userSpaceOnUse" x1="100%" y1="100%" x2="0%" y2="0%">
                    <stop offset="0%" :style="`stop-color:${startColor}`"/>
                    <stop offset="100%" :style="`stop-color:${endColor}`"/>
                </linearGradient>
                <!--第二个粗圆环 作为进度的底部颜色-->
                <circle 
                    :cx="size" 
                    :cy="size" 
                    :r="raduisActual" 
                    :stroke-width="strokeWidth" 
                    stroke="#F7F7F7"
                    fill="none"
                />
                <!--第三个圆环： 实际进度-->
                <circle
                    v-if="rate" 
                    :cx="size" 
                    :cy="size" 
                    :r="raduisActual" 
                    :stroke-width="strokeWidth" 
                    stroke="url(#svg-gradient)"
                    transform="rotate(-90 55 55)"
                    :stroke-dasharray="strokeDasharray"
                    stroke-linecap="round" 
                    fill="none"
                />
            </svg>
        </div>
        <slot name="txt"></slot>
    </div>
</template>

<script>
export default {
   name: "gauge",
    props: {
        // 圆环的宽度
        strokeWidth: {
            default: 10,
            type: Number,
        },
        // 圆环的颜色
        strokeColor: {
            type: String,
            default: '#415FF6'
        },
        // 圆环显示的百分比 这边是小数
        rate: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            size: 55, // 圆环半径
            curline: 10,
            startColor: '',
            endColor: ''
        }
    },
    computed: {
        raduisActual() {
            return this.size - this.strokeWidth - 3;
        },
        diameterShow() {
            return 2 * this.size;
        },
        viewbox() {
            return `0 0 ${this.diameterShow} ${this.diameterShow}`;
        },
        strokeDasharray() {
            const that = this;
            const perimeter = 260 * (this.rate/100);
            if (perimeter >= 130) {
                that.startColor = '#7398F8';
                that.endColor = '#415FF6';
            } else {
                that.startColor = '#FFAA32';
                that.endColor = '#FF7F32';
            }
            return `${perimeter} 260`;
        }
    },
    mounted() {
        // if (this.rate >= 50) {
        //     this.startColor = '#7398F8';
        //     this.endColor = '#415FF6';
        // } else {
        //     this.startColor = '#FFAA32';
        //     this.endColor = '#FF7F32';
        // }
    },
    methods: {
        setTicks(i, key) {
            let angle = 1 - i * (6/180*Math.PI);
            let temp_angle = Math.PI+angle;
            let width = 100;
            let height = 100;
            let obj = {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 0,
            };

            obj.x1 = width / 2 + 74 * Math.cos(angle),
            obj.y1 = height / 2 + 74 * Math.sin(angle);

            obj.x2= obj.x1+(i === this.curline ? 6:4)*Math.cos(temp_angle);
            obj.y2= obj.y1+(i === this.curline ? 6:4)*Math.sin(temp_angle);

            return obj[key];

        }
    }
}
</script>

<style scoped lang="less">
.parent-element-center {
    height: 110px;
    width: 110px;
    display: inline-block;
    position: absolute;
    left: -10px;
    top: -10px;
    .svg-wrap {
        width: 100%;
        height: 100%;
    }
    .tick-path {
        position: absolute;
        left: 0;
        top: 0;
        transform: rotate(-24deg);
    }
}
</style>